<template>
  <fp-form-modal
      :title="$t('partner.participantList.communicationTab.sendMessages')"
      v-model="visible"
      size="lg"
  >
    <template #content>
      <new-messages-modal v-model="openMessageEditor" :participantId="participantId"/>
      <view-messages-modal v-model="openMessageViewer" :messages="messages"/>
      <b-icon
          class="h4"
          style="cursor: pointer"
          icon="pencil-square"
          @click="openNewMessages"
      ></b-icon>
      <fp-table-list :items="templateList" :fields="templatedListFields">
        <template #SupportedLanguages="row">
          <span v-for="lang in row.item.SupportedLanguages" :key="lang">
            <img
                style="margin-right: 5px"
                width="20px"
                :src="require('@/Assets' + $enums.Languages[lang].flag)"
            />
          </span>
        </template>
        <template #SupportedTypes="row">
          <b-icon
              style="margin-right:5px"
              v-if="row.item.EmailTemplate"
              icon="envelope-fill"
          ></b-icon>
          <b-icon
              style="margin-right:5px"
              v-if="row.item.SmsTemplate"
              icon="chat-square-text-fill"
          ></b-icon>
          <b-icon
              style="margin-right:5px"
              v-if="row.item.PushTemplate"
              icon="phone-vibrate"
          ></b-icon>
        </template>
        <template #Options="row">
          <b-icon
              class="h4"
              style="cursor: pointer;margin-right:5px"
              icon="eye-fill"
              @click="viewMessages(row.item)"
              v-b-tooltip.hover="$t('base.basic.view')"
          />
          <b-iconstack
              class="h4"
              style="cursor: pointer"
              @click="sendTemplate(row.item.TemplateId)"
              v-b-tooltip.hover="
              $t('partner.participantList.communicationTab.send')
            "
          >
            <b-icon stacked icon="cursor-fill"></b-icon>
            <b-icon
                stacked
                icon="slash"
                style="color:white"
                shift-v="1.2"
                shift-h="1.5"
                scale="0.9"
            ></b-icon>
          </b-iconstack>
        </template>
      </fp-table-list>
    </template>
  </fp-form-modal>
</template>
<script>
import {TemplateLogic} from "../../../../../Logic/Backend/template";
import {NotificationLogic} from "../../../../../Logic/Backend/notification";
import NewMessagesModal from "./NewMessagesModal.vue";
import ViewMessagesModal from "./ViewMessagesModal.vue";

export default {
  components: {NewMessagesModal, ViewMessagesModal},
  name: "ParticipantCommunicationTemplates",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Boolean,
    participantId: Number,
    project: Object,
  },
  data() {
    return {
      templateList: null,
      templatedListFields: [
        {
          key: "Name",
          label: this.$t(
              "partner.participantList.communicationTab.templateName"
          ),
        },
        {
          key: "SupportedLanguages",
          label: this.$t(
              "partner.participantList.communicationTab.templateSupportedLanguages"
          ),
        },
        {
          key: "SupportedTypes",
          label: this.$t(
              "partner.participantList.communicationTab.templateMessageTypes"
          ),
        },
        {key: "Options", label: ""},
      ],
      visible: this.value,
      messages: null,
      openMessageEditor: false,
      openMessageViewer: false,
    };
  },
  watch: {
    value(input) {
      this.visible = input;
    },
    visible(input) {
      this.$emit("change", input);
    },
  },
  methods: {
    openNewMessages() {
      this.messages = null;
      this.openMessageEditor = true;
    },
    viewMessages(template) {
      this.messages = template;
      this.openMessageViewer = true;
    },
    async sendTemplate(templateId) {
      const body = {
        RecipientId: this.participantId,
        TemplateId: templateId,
        EmailAttachments:[]
      };
      const sendTemplateResponse = await NotificationLogic.sendTemplate(body);
      if (!sendTemplateResponse.Code || sendTemplateResponse.Code == 0) {
        this.$bvToast.toast(
            this.$t("requestResponse.communication.successSendMessage"),
            {
              title: this.$t("requestResponse.basic.successSend"),
              variant: "success",
              solid: true,
            }
          );
          this.$emit('refreshList');
      } else {
        this.$bvToast.toast(sendTemplateResponse.Message, {
          title: this.$t("requestResponse.communication.errorSendMessage"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async getTemplates() {
      const getTemplatesResponse = await TemplateLogic.getTemplates();
      if (!getTemplatesResponse.Code) {
        this.templateList = getTemplatesResponse.filter((temp) => {
          return this.project.Items.some(
              (item) =>
                  item.EntityTypeName === "NotificationTemplate" &&
                  temp.TemplateId === item.EntityId
          );
        });
      } else {
        this.$bvToast.toast(getTemplatesResponse.Message, {
          title: this.$t("requestResponse.communication.errorGetTemplate"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
  async mounted() {
    await this.getTemplates();
  },
};
</script>
