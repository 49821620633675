<template>
  <div style="margin: 10px">
    <div class="eh-flex-container">
      <!-- <fp-input
          class="eh-margin-right"
          :label="$t('base.basic.filter')"
          @change="filterList"
          v-model="filter"
        /> -->
      <fp-date-range-button
        :label="$t('partner.participantList.communicationTab.sendTime')"
        v-model="addDateFilter"
        @change="filterList"
      />
      <!-- Hívás -->
      <b-button class="eh-action-button">
        {{ $t("partner.participantList.communicationTab.call") }}
      </b-button>
      <!-- Videó hívás -->
      <!-- TODO: szótárazni -->
      <b-button class="eh-action-button"> Videó hívás </b-button>
      <!-- Üzenet küldés -->
      <b-button class="eh-action-button" @click="openTemplate = true">
        {{ $t("partner.participantList.communicationTab.sendMessages") }}
      </b-button>
      <template-list
        v-model="openTemplate"
        :participantId="participant.UserId"
        :project="project"
        :templateList="templateList"
        @refreshList="filterList"
      />
    </div>

    <!-- Napló lista -->
    <fp-table-list
      :hasPagination="true"
      :limitedDataLoad="true"
      @nextStackLoad="getNextStack"
      :items="communicationHistory"
      :fields="communicationHistoryFields"
      sortBy="CreatedAt"
      :sortDesc.sync="sortDesc"
    >
      <template #Types="row">
        <div v-if="row.item.NotificationEmailSubject">
          Sablon cím: {{ row.item.NotificationEmailSubject }}
        </div>
        <div v-else-if="row.item.NotificationTemplateTitle">
          Email tárgy: {{ row.item.NotificationTemplateTitle }}
        </div>
        <div
          v-if="
            getTargetNotificationFromList(row.item) &&
            getTargetNotificationFromList(row.item).Email
          "
        >
          <b-icon
            style="margin-right: 5px"
            icon="envelope-fill"
            :variant="
              getStatus(
                row.item.Parts.find(
                  (part) => part.TargetId == participant.UserId
                ).Email
              )
            "
          >
          </b-icon>
          Email
        </div>
        <div
          v-if="
            getTargetNotificationFromList(row.item) &&
            getTargetNotificationFromList(row.item).Sms
          "
        >
          <b-icon
            style="margin-right: 5px"
            icon="chat-square-text-fill"
            :variant="
              getStatus(
                row.item.Parts.find(
                  (part) => part.TargetId == participant.UserId
                ).Sms
              )
            "
          >
          </b-icon>
          SMS
        </div>
        <div
          v-if="
            getTargetNotificationFromList(row.item) &&
            getTargetNotificationFromList(row.item).Push
          "
        >
          <b-icon
            style="margin-right: 5px"
            icon="phone-vibrate"
            :variant="
              getStatus(
                row.item.Parts.find(
                  (part) => part.TargetId == participant.UserId
                ).Push
              )
            "
          >
          </b-icon>
          Push
        </div>
      </template>
      <template #CreatedAt="row">
        {{ $convertDateToString(row.item.CreatedAt, "YYYY-MM-DD HH:mm") }}
      </template>

      <template #Operations="row">
        <b-icon
          class="h4"
          style="cursor: pointer; margin-right: 5px"
          icon="eye-fill"
          @click="$set(row.item, '_showDetails', !row.item._showDetails)"
        />
      </template>

      <template #row-details="row">
        <!-- Email sablon -->
        <fp-panel-card
          :closedCard="true"
          v-if="
            getTargetNotificationFromList(row.item) &&
            getTargetNotificationFromList(row.item).Email
          "
          style="margin: 10px 0px"
          :title="$t('partner.participantList.communicationTab.email')"
        >
          <!-- Típus -->
          <template #title>
            <b-icon style="margin: 0px 5px" icon="envelope-fill"></b-icon>
            {{ $t("partner.participantList.communicationTab.email") }}
          </template>

          <!-- Adatok -->
          <template #content>
            <div
              class="contenttext"
              v-if="
                row.item.Parts.find(
                  (part) => part.TargetId == participant.UserId
                ).Email.Result.Code != 0
              "
              v-html="
                row.item.Parts.find(
                  (part) => part.TargetId == participant.UserId
                ).Email.Result.Message
              "
            />
            <!-- Tárgy -->
            <fp-input
              style="margin: 10px 0px"
              :label="
                $t('partner.participantList.communicationTab.emailSubject')
              "
              :disabled="true"
              v-model="getTargetNotificationFromList(row.item).Email.Subject"
            />
            <div
              class="content"
              v-html="getTargetNotificationFromList(row.item).Email.HtmlContent"
            />
          </template>
        </fp-panel-card>
        <!-- SMS sablon -->
        <fp-panel-card
          :closedCard="true"
          v-if="
            getTargetNotificationFromList(row.item) &&
            getTargetNotificationFromList(row.item).Sms
          "
          style="margin: 10px 0px"
          :title="$t('partner.participantList.communicationTab.sms')"
        >
          <!-- Sablon típusa -->
          <template #title>
            <b-icon
              style="margin: 0px 5px"
              icon="chat-square-text-fill"
            ></b-icon>
            {{ $t("partner.participantList.communicationTab.sms") }}
          </template>
          <!-- Sablon adatok -->
          <template #content>
            <div
              class="contenttext"
              v-if="
                row.item.Parts.find(
                  (part) => part.TargetId == participant.UserId
                ).Sms.Result.Code != 0
              "
              v-html="
                row.item.Parts.find(
                  (part) => part.TargetId == participant.UserId
                ).Sms.Result.Message
              "
            />
            <!-- SMS üzenet -->
            <fp-text-area
              :label="$t('partner.participantList.communicationTab.smsText')"
              :disabled="true"
              v-model="getTargetNotificationFromList(row.item).Sms.Text"
            />
          </template>
        </fp-panel-card>
        <!-- PUSH sablon -->
        <fp-panel-card
          :closedCard="true"
          v-if="
            getTargetNotificationFromList(row.item) &&
            getTargetNotificationFromList(row.item).Push
          "
          style="margin: 10px 0px"
          :title="$t('partner.participantList.communicationTab.push')"
        >
          <!-- Sablon típusa -->
          <template #title>
            <b-icon style="margin: 0px 5px" icon="phone-vibrate"></b-icon>
            {{ $t("partner.participantList.communicationTab.push") }}
          </template>
          <!-- Sablon adatok -->
          <template #content>
            <div
              class="contenttext"
              v-if="
                row.item.Parts.find(
                  (part) => part.TargetId == participant.UserId
                ).Push.Result.Code != 0
              "
              v-html="
                row.item.Parts.find(
                  (part) => part.TargetId == participant.UserId
                ).Push.Result.Message
              "
            />
            <!-- Push címe -->
            <fp-input
              :label="$t('partner.participantList.communicationTab.pushTitle')"
              :disabled="true"
              v-model="getTargetNotificationFromList(row.item).Push.Title"
            />
            <!-- Push üzenet -->
            <fp-text-area
              :label="$t('partner.participantList.communicationTab.pushText')"
              :disabled="true"
              v-model="getTargetNotificationFromList(row.item).Push.Body"
            />
            <!-- Push képe -->
            <fp-input
              :label="$t('partner.participantList.communicationTab.pushPhoto')"
              :disabled="true"
              v-model="getTargetNotificationFromList(row.item).Push.ImageUrl"
            />
            <div>
              <img
                width="200px"
                v-if="
                  row.item.Parts.find(
                    (part) => part.TargetId == participant.UserId
                  ).Push.ImageUrl
                "
                :src="
                  row.item.Parts.find(
                    (part) => part.TargetId == participant.UserId
                  ).Push.ImageUrl
                "
              />
            </div>
          </template>
        </fp-panel-card>
      </template>
    </fp-table-list>
  </div>
</template>
<script>
import TemplateList from "./tabs-components/Communication/TemplateList.vue";
import { NotificationLogic } from "../../../Logic/Backend/notification";
import { TemplateLogic } from "../../../Logic/Backend/template";

export default {
  components: { TemplateList },
  data() {
    return {
      communicationHistory: null,
      communicationHistoryFields: [
        /* {
          key: "NotificationId",
          label: "Azonosító",
        }, */
        {
          key: "CreatedAt",
          label: this.$t("partner.participantList.communicationTab.sendTime"),
          sortable: true,
        },
        {
          key: "Types",
          label: this.$t(
            "partner.participantList.communicationTab.communicationTypes"
          ),
        },
        {
          key: "Operations",
          label: "Műveletek",
        },
      ],
      openDiary: false,
      selectedCommunication: {},
      openNewMessage: false,
      openTemplate: false,
      filter: null,
      addDateFilter: null,
      sortDesc: true,
      templateList: null,
    };
  },
  props: {
    participant: Object,
    project: Object,
  },
  watch: {
    async participant() {
      this.communicationHistory = await this.getSentNotifications();
    },
    async sortDesc() {
      await filterList();
    },
  },
  methods: {
    async setNotificationTitle(notiList) {
      await Promise.all(
        notiList.map(async (n) => {
          if (n.TemplateId) {
            n.NotificationEmailSubject = await this.getTemplateTitle(
              n.TemplateId
            );
          } else if (n.Parts.some((x) => x.Email && x.Email.Subject)) {
            n.NotificationTemplateTitle = n.Parts.find(
              (x) => x.Email
            ).Email.Subject;
          }
        })
      );
      return notiList;
    },
    async getTemplateTitle(templateId) {
      const templateResponse = await TemplateLogic.getTemplates({
        TemplateId: templateId,
      });
      if (!templateResponse.Code && templateResponse && templateResponse.length) {
        return templateResponse[0].Name;
      } else {
        return "A korábban kiküldött sablon már törölve lett.";
      }
    },
    getTargetNotificationFromList(item) {
      return item.Parts.find(
        (part) => part.TargetId == this.participant.UserId
      );
    },
    async filterList() {
      var params = {};
      if (this.addDateFilter) {
        params = {
          ...params,
          "CreatedAt-to": this.addDateFilter ? this.addDateFilter.to : null,
          "CreatedAt-from": this.addDateFilter ? this.addDateFilter.from : null,
        };
      }
      this.communicationHistory = await this.getSentNotifications(params);
    },
    async getSentNotifications(params) {
      const getNotiResponse = await NotificationLogic.getSentNotification({
        SenderId: this.$loggedUser.UserId,
        TargetId: this.participant.UserId,
        ...params,
        ["-orderby" + (this.sortDesc ? "desc" : "")]: "CreatedAt",
        "-limit": 101,
      });

      if (!getNotiResponse.Code) {
        return await this.setNotificationTitle(
          this.filterUser(getNotiResponse)
        );
      } else {
        this.$bvToast.toast(getNotiResponse.Message, {
          title: this.$t("requestResponse.communication.errorMessagesList"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
        return [];
      }
    },
    async getNextStack() {
      const lastElement =
        this.communicationHistory[this.communicationHistory.length - 1];
      const filterParams = {
        "CreatedAt-to": this.addDateFilter ? this.addDateFilter.to : null,
        "CreatedAt-from": this.addDateFilter ? this.addDateFilter.from : null,
      };
      const stackParams = {
        ["CreatedAt-" + (this.sortDesc ? "to" : "from")]: lastElement.CreatedAt,
      };
      const newStack = await this.getSentNotifications(
        Object.assign(filterParams, stackParams)
      );
      const newSumList = this.communicationHistory.concat(newStack);
      this.communicationHistory = this.$checkDuplicates(
        newSumList,
        "NotificationId"
      );
    },
    filterUser(elements) {
      return elements.filter((e) =>
        e.Parts.some((part) => part.TargetId === this.participant.UserId)
      );
    },
    getStatus(item) {
      if (item.Result.Code == 0) {
        return "success";
      } else {
        return "danger";
      }
    },
  },
  async mounted() {
    this.communicationHistory = await this.getSentNotifications();
  },
};
</script>
<style scoped>
.filter-operation {
  margin-right: 10px;
}
.content {
  background-color: white;
  padding: 20px;
}
.contenttext {
  padding: 5px;
  font-size: 12px;
  color: red;
  font-weight: bold;
}
</style>
